import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useState } from 'react';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../api/ParkingaboApi';
import {
    useServerErrorEffect,
    useServerFetch,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ParkingaboUser } from '../shared/ParkingaboModels';
import { ParkingaboVehicle } from 'dg-web-shared/common/models/Vehicle';
import { ParkingaboAsyncLoadedSection } from '../components/layout/ParkingaboAsyncLoadedSection';
import { VehiclesRoute } from './authorized/vehicles/VehiclesRoute';
import { VehiclesDetailRoute } from './authorized/vehicles/VehiclesDetailRoute';
import { AddVehicleRoute } from './authorized/vehicles/AddVehicleRoute';
import { ProductsAddOutlet } from './authorized/products/ProductsAddOutlet';
import { ProductsConfigurationOutlet } from './authorized/products/ProductsConfigurationOutlet';
import { ProductsSelectionOutlet } from './authorized/products/ProductsSelectionOutlet';
import { ProductsRoute } from './authorized/products/ProductsRoute';
import { ProductsDetailOutlet } from './authorized/products/ProductsDetailOutlet';
import { ParkingaboProductListItem } from '../shared/ParkingaboProductModels';
import { AddPaymentMethodRoute } from './authorized/payment/AddPaymentMethodRoute';
import { PaymentMethodType } from '../components/forms/PaymentMethodForm';
import { PaymentAbortedRoute } from './authorized/payment/PaymentAbortedRoute';
import { PaymentMethodCheckRoute } from './authorized/payment/PaymentMethodCheckRoute';
import { OnboardingPaymentMethodRoute } from './authorized/onboarding/OnboardingPaymentMethodRoute';
import { OnboardingUserDataRoute } from './authorized/onboarding/OnboardingUserDataRoute';
import { OnboardingVehicleRoute } from './authorized/onboarding/OnboardingVehicleRoute';
import { OnboardingSuccessRoute } from './authorized/onboarding/OnboardingSuccessRoute';
import { HomeRoute } from './authorized/HomeRoute';
import { TenantSelectionRoute } from './authorized/TenantSelectionRoute';
import { TenantContextProvider } from '../components/TenantProvider.tsx';
import { ProductsAccessChecker } from '../components/ProductsAccessChecker';
import { SettingsRoute } from './authorized/settings/SettingsRoute';
import { PaymentSettingsRoute } from './authorized/settings/PaymentSettingsRoute';
import { EmailResentConfirmationRoute } from './authorized/settings/EmailResentConfirmationRoute';
import { AddPaymentSuccessRoute } from './authorized/settings/AddPaymentSuccessRoute';
import { EditLanguageRoute } from './authorized/settings/EditLanguageRoute';
import { EditNameRoute } from './authorized/settings/EditNameRoute';
import { EditEmailRoute } from './authorized/settings/EditEmailRoute';
import { EditPasswordRoute } from './authorized/settings/EditPasswordRoute';
import { EditProfileRoute } from './authorized/settings/EditProfileRoute';
import { LoginRoute, TenantLoginRoute } from './public/LoginRoute';
import {
    ParkingaboRegistrationRoute,
    ParkingaboRegistrationWithBadge,
    ParkingaboRegistrationWithCustomer,
    ParkingaboTenantRegistrationRoute,
} from './public/ParkingaboRegistrationRoute';
import { RegistrationConfirmationRoute } from './public/RegistrationConfirmationRoute';
import {
    RegistrationLinkExpiredRoute,
    RegistrationLinkExpiredWithBadge,
    RegistrationLinkExpiredWithCustomer,
} from './public/RegistrationLinkExpiredRoute';
import { RequestPasswordResetRoute } from './public/RequestPasswordResetRoute';
import { PasswordRequestSentSuccessRoute } from './public/PasswordRequestSentSuccessRoute';
import { ResetPasswordRoute } from './public/ResetPasswordRoute';
import { ResetPasswordSuccessRoute } from './public/ResetPasswordSuccessRoute';
import { ResetPasswordTokenErrorRoute } from './public/ResetPasswordTokenErrorRoute';
import { EmailVerificationRoute } from './public/EmailVerificationRoute';
import {
    AccountStatementItemDetail,
    AccountStatementOutlet,
} from './authorized/account-statement/AccountStatementOutlet';
import { EmailConfirmedModalRoute } from './public/EmailConfirmedModalRoute';
import { ProductsPurchaseAccessChecker } from '../components/ProductsPurchaseAccessChecker';
import { AccountLockedMessageRoute } from './authorized/AccountLockedMessageRoute';
import { OnboardingRequiredChecker } from '../components/OnboardingRequiredChecker';
import {
    NewPaymentRegistrationRequiredRoute,
    PaymentOverdueRegistrationRequiredRoute,
} from './authorized/payment/NewPaymentRegistrationRequiredRoute';
import { ReplacePaymentRoute } from './authorized/settings/ReplacePaymentRoute';
import {
    AuthedParkingaboBadgeValidationRoute,
    PublicParkingaboBadgeValidationRoute,
} from './public/ParkingaboBadgeValidationRoute.tsx';
import { ErrorAlert } from 'dg-web-shared/common/components/material-ui/ErrorAlert.tsx';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';
import { OnboardingProvider } from './authorized/onboarding/OnboardingConfig.tsx';
import { ParkingaboPrivacy } from './Privacy.tsx';
import { UnauthenticatedTenantContextProvider } from '../components/layout/UnauthenticatedTenantProvider.tsx';
import { NativeContextProdivder, setAppToken } from '../components/Native.tsx';
import { QRCodeParsingErrorMessage } from './public/QRCodeParsingErrorMessage.tsx';
import { LandingPage } from './public/LandingPage.tsx';
import {
    AuthedParkingaboCustomerValidationRoute,
    PublicParkingaboCustomerValidationRoute,
} from './public/ParkingaboCustomerValidationRoute.tsx';

export interface ParkingaboLogin {
    loggedIn: boolean;
    logout: () => void;
    allowsSignup: boolean | null;
    appReadyToBeShown: boolean;
    linkedUsers: ParkingaboUser[];
    refetchLogin: () => void;
}

export function useLogin(): ParkingaboLogin {
    const [loggedIn, setLoggedIn] = useState(false);
    const [appReadyToBeShown, setAppReadyToBeShown] = useState(false);

    const [logoutState, logout] = useParkingaboServerWrite(() => ({
        url: '/ui-api/parkingabo/session/logout',
    }));

    useServerSuccessEffect(logoutState, () => {
        setLoggedIn(false);
        setAppToken(null);
        window.location.reload();
    });

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const [loginState, refetchLogin] = useServerFetch<
        { linkedUsers: ParkingaboUser[] },
        object
    >(
        () => ({
            url: `/ui-api/parkingabo/login`,
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
        }),
        {},
    );

    useServerSuccessEffect(loginState, response => {
        if (response.linkedUsers.length < 1) {
            logout();
            return;
        }
        setLoggedIn(true);
        setAppReadyToBeShown(true);
    });

    useServerErrorEffect(loginState, statusCode => {
        if (statusCode === 401) {
            setLoggedIn(false);
            setAppReadyToBeShown(true);
        }
    });

    return {
        loggedIn,
        logout: () => logout(),
        allowsSignup: false,
        appReadyToBeShown: appReadyToBeShown,
        linkedUsers: loginState.data?.linkedUsers ?? [],
        refetchLogin,
    };
}

export function AppRoutes({ login }: { login: ParkingaboLogin }) {
    const theme = useTheme();

    if (!login.appReadyToBeShown) {
        return (
            <div
                style={{
                    backgroundColor: theme.palette.grey[400],
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    if (!login.loggedIn) {
        return <RouterProvider router={publicRouter(login)} />;
    }

    return (
        <AuthedRoutes
            linkedUsers={login.linkedUsers}
            refetchLogin={login.refetchLogin}
            logout={login.logout}
        />
    );
}

function NotFound() {
    return (
        <ErrorAlert>
            <p>
                <Localized
                    de="Es ist ein Fehler aufgetreten."
                    fr="Une erreur est survenue."
                    it="Si è verificato un errore."
                    en="An error has occurred."
                />
            </p>
            <p>
                <Localized
                    de="Bitte wenden Sie sich direkt an den Betreiber."
                    fr="Veuillez contacter directement l'exploitant."
                    it="Vogliate rivolgetevi direttamente al gestore."
                    en="Please contact the operator directly."
                />
            </p>
        </ErrorAlert>
    );
}

function publicRouter(login: ParkingaboLogin) {
    const registrationRoutes = [];
    if (login.allowsSignup) {
        registrationRoutes.push({
            path: '/register',
            element: <ParkingaboRegistrationRoute />,
            children: [
                {
                    path: 'email-sent',
                    element: <RegistrationConfirmationRoute />,
                },
                {
                    path: 'link-expired/:token',
                    element: <RegistrationLinkExpiredRoute />,
                },
            ],
        });
    }
    return createBrowserRouter(
        [
            {
                element: <NativeContextProdivder />,
                children: [
                    { element: <LandingPage />, index: true },
                    {
                        path: '/privacy',
                        element: <ParkingaboPrivacy />,
                    },
                    {
                        path: '/login',
                        element: <LoginRoute login={login} />,
                        children: [
                            {
                                path: 'email-confirmed',
                                element: (
                                    <EmailConfirmedModalRoute isRegistration />
                                ),
                            },
                            {
                                path: 'qr-code-parsing-failed',
                                element: <QRCodeParsingErrorMessage />,
                            },
                        ],
                    },
                    {
                        path: '/verify/:token',
                        element: <EmailVerificationRoute />,
                    },
                    ...registrationRoutes,
                    {
                        path: '/:tenantId',
                        element: <UnauthenticatedTenantContextProvider />,
                        children: [
                            {
                                path: 'login',
                                element: <TenantLoginRoute login={login} />,
                                children: [
                                    {
                                        path: 'email-confirmed',
                                        element: (
                                            <EmailConfirmedModalRoute
                                                isRegistration
                                            />
                                        ),
                                    },
                                ],
                            },
                            {
                                path: 'register',
                                element: <ParkingaboTenantRegistrationRoute />,
                                children: [
                                    {
                                        path: 'email-sent',
                                        element: (
                                            <RegistrationConfirmationRoute />
                                        ),
                                    },
                                    {
                                        path: 'link-expired/:token',
                                        element: (
                                            <RegistrationLinkExpiredRoute />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '/request-password-reset',
                        element: <RequestPasswordResetRoute />,
                        children: [
                            {
                                path: 'success',
                                element: <PasswordRequestSentSuccessRoute />,
                            },
                        ],
                    },
                    {
                        path: '/reset-password/:token',
                        element: <ResetPasswordRoute />,
                        children: [
                            {
                                path: 'error',
                                element: <ResetPasswordTokenErrorRoute />,
                            },
                            {
                                path: 'success',
                                element: <ResetPasswordSuccessRoute />,
                            },
                        ],
                    },
                    {
                        path: '/b/:badgeLabelNr',
                        element: <PublicParkingaboBadgeValidationRoute />,
                    },
                    {
                        path: '/register-with-badge/:badgeLabelNr',
                        element: <ParkingaboRegistrationWithBadge />,
                        children: [
                            {
                                path: 'email-sent',
                                element: <RegistrationConfirmationRoute />,
                            },
                            {
                                path: 'link-expired/:token',
                                element: <RegistrationLinkExpiredWithBadge />,
                            },
                        ],
                    },
                    {
                        path: '/c/:customerNr',
                        element: <PublicParkingaboCustomerValidationRoute />,
                    },
                    {
                        path: '/register-with-customer/:customerNr',
                        element: <ParkingaboRegistrationWithCustomer />,
                        children: [
                            {
                                path: 'email-sent',
                                element: <RegistrationConfirmationRoute />,
                            },
                            {
                                path: 'link-expired/:token',
                                element: (
                                    <RegistrationLinkExpiredWithCustomer />
                                ),
                            },
                        ],
                    },
                    {
                        path: '*',
                        element: <Navigate to="/login" replace />,
                    },
                ],
            },
        ],
        { future: { v7_normalizeFormMethod: true } },
    );
}

export function AuthedRoutes({
    logout,
    linkedUsers,
    refetchLogin,
}: {
    logout: () => void;
    linkedUsers: ParkingaboUser[];
    refetchLogin: () => void;
}) {
    const user = linkedUsers[0];
    const [productState, refetchProducts] = useParkingaboServerFetch<
        ParkingaboProductListItem[]
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self/product`,
        }),
        {},
    );
    const [vehiclesState, refetchVehicles] = useParkingaboServerFetch<
        ParkingaboVehicle[]
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self/vehicles`,
        }),
        {},
    );

    return (
        <ParkingaboAsyncLoadedSection
            state={productState}
            render={products => (
                <ParkingaboAsyncLoadedSection
                    state={vehiclesState}
                    render={vehiclesData => {
                        const vehiclesStatus = vehiclesState.status;
                        const vehicles = {
                            data: vehiclesData,
                            status: vehiclesStatus,
                            refetch: refetchVehicles,
                        };
                        const baseProps = {
                            logout,
                            user,
                            refetchUser: refetchLogin,
                            products,
                            refetchProducts,
                            vehicles,
                        };

                        return (
                            <RouterProvider
                                router={createBrowserRouter([
                                    {
                                        path: '',
                                        element: (
                                            <TenantSelectionRoute
                                                {...baseProps}
                                            />
                                        ),
                                    },
                                    {
                                        path: '/privacy',
                                        element: <ParkingaboPrivacy />,
                                    },
                                    {
                                        path: '/verify/:token',
                                        element: (
                                            <EmailVerificationRoute
                                                user={user}
                                                refetchUser={refetchLogin}
                                            />
                                        ),
                                    },
                                    {
                                        path: '/b/:badgeLabelNr',
                                        element: (
                                            <AuthedParkingaboBadgeValidationRoute />
                                        ),
                                    },
                                    {
                                        path: '/c/:customerNr',
                                        element: (
                                            <AuthedParkingaboCustomerValidationRoute />
                                        ),
                                    },
                                    {
                                        path: ':tenantId',
                                        element: (
                                            <TenantContextProvider
                                                {...baseProps}
                                            />
                                        ),
                                        children: [
                                            {
                                                element: (
                                                    <OnboardingProvider
                                                        {...baseProps}
                                                    />
                                                ),
                                                children: [
                                                    {
                                                        element: (
                                                            <OnboardingRequiredChecker
                                                                {...baseProps}
                                                            />
                                                        ),
                                                        children: [
                                                            {
                                                                index: true,
                                                                element: (
                                                                    <HomeRoute
                                                                        {...baseProps}
                                                                    />
                                                                ),
                                                            },
                                                            {
                                                                path: 'link-expired/:token',
                                                                element: (
                                                                    <RegistrationLinkExpiredRoute />
                                                                ),
                                                            },
                                                            {
                                                                path: 'email-confirmed',
                                                                element: (
                                                                    <EmailConfirmedModalRoute />
                                                                ),
                                                            },
                                                            {
                                                                path: 'onboarding/success',
                                                                element: (
                                                                    <OnboardingSuccessRoute />
                                                                ),
                                                            },
                                                            {
                                                                path: 'locked-account',
                                                                element: (
                                                                    <AccountLockedMessageRoute />
                                                                ),
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        children: [
                                                            {
                                                                path: 'onboarding',
                                                                children: [
                                                                    {
                                                                        path: 'vehicle',
                                                                        element:
                                                                            (
                                                                                <OnboardingVehicleRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: 'user-data',
                                                                        element:
                                                                            (
                                                                                <OnboardingUserDataRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: 'payment',
                                                                        element:
                                                                            (
                                                                                <OnboardingPaymentMethodRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        children:
                                                                            [
                                                                                {
                                                                                    path: 'aborted',
                                                                                    element:
                                                                                        (
                                                                                            <PaymentAbortedRoute />
                                                                                        ),
                                                                                },
                                                                            ],
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                path: 'onboarding-with-badge',
                                                                children: [
                                                                    {
                                                                        path: 'payment',
                                                                        element:
                                                                            (
                                                                                <OnboardingPaymentMethodRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        children:
                                                                            [
                                                                                {
                                                                                    path: 'aborted',
                                                                                    element:
                                                                                        (
                                                                                            <PaymentAbortedRoute />
                                                                                        ),
                                                                                },
                                                                            ],
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                path: 'register-payments/payment/:transactionId',
                                                                element: (
                                                                    <PaymentMethodCheckRoute
                                                                        paymentMethodType={
                                                                            PaymentMethodType.REGISTER
                                                                        }
                                                                        {...baseProps}
                                                                    />
                                                                ),
                                                            },
                                                            {
                                                                path: 'onboarding-payments/payment/:transactionId',
                                                                element: (
                                                                    <PaymentMethodCheckRoute
                                                                        paymentMethodType={
                                                                            PaymentMethodType.ONBOARDING
                                                                        }
                                                                        {...baseProps}
                                                                    />
                                                                ),
                                                            },
                                                            {
                                                                path: 'payment',
                                                                children: [
                                                                    {
                                                                        path: 'register',
                                                                        element:
                                                                            (
                                                                                <AddPaymentMethodRoute
                                                                                    paymentMethodType={
                                                                                        PaymentMethodType.REGISTER
                                                                                    }
                                                                                />
                                                                            ),
                                                                        children:
                                                                            [
                                                                                {
                                                                                    path: 'aborted',
                                                                                    element:
                                                                                        (
                                                                                            <PaymentAbortedRoute />
                                                                                        ),
                                                                                },
                                                                            ],
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                element: (
                                                                    <OnboardingRequiredChecker
                                                                        {...baseProps}
                                                                    />
                                                                ),
                                                                children: [
                                                                    {
                                                                        path: 'vehicles',
                                                                        element:
                                                                            (
                                                                                <VehiclesRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        children:
                                                                            [
                                                                                {
                                                                                    path: 'add',
                                                                                    element:
                                                                                        (
                                                                                            <AddVehicleRoute
                                                                                                {...baseProps}
                                                                                            />
                                                                                        ),
                                                                                },
                                                                                {
                                                                                    path: ':vehicleId',
                                                                                    element:
                                                                                        (
                                                                                            <VehiclesDetailRoute
                                                                                                {...baseProps}
                                                                                            />
                                                                                        ),
                                                                                },
                                                                            ],
                                                                    },
                                                                    {
                                                                        element:
                                                                            (
                                                                                <ProductsAccessChecker
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        children:
                                                                            [
                                                                                {
                                                                                    path: 'products',
                                                                                    element:
                                                                                        (
                                                                                            <ProductsRoute
                                                                                                {...baseProps}
                                                                                            />
                                                                                        ),
                                                                                    children:
                                                                                        [
                                                                                            {
                                                                                                path: 'payment-missing',
                                                                                                element:
                                                                                                    (
                                                                                                        <NewPaymentRegistrationRequiredRoute />
                                                                                                    ),
                                                                                            },
                                                                                            {
                                                                                                path: 'payment-overdue',
                                                                                                element:
                                                                                                    (
                                                                                                        <PaymentOverdueRegistrationRequiredRoute
                                                                                                            {...baseProps}
                                                                                                        />
                                                                                                    ),
                                                                                            },
                                                                                            {
                                                                                                element:
                                                                                                    (
                                                                                                        <ProductsPurchaseAccessChecker
                                                                                                            {...baseProps}
                                                                                                        />
                                                                                                    ),
                                                                                                children:
                                                                                                    [
                                                                                                        {
                                                                                                            path: ':contractId',
                                                                                                            element:
                                                                                                                (
                                                                                                                    <ProductsDetailOutlet
                                                                                                                        {...baseProps}
                                                                                                                    />
                                                                                                                ),
                                                                                                        },
                                                                                                        {
                                                                                                            path: 'add',
                                                                                                            element:
                                                                                                                (
                                                                                                                    <ProductsAddOutlet />
                                                                                                                ),
                                                                                                            children:
                                                                                                                [
                                                                                                                    {
                                                                                                                        index: true,
                                                                                                                        element:
                                                                                                                            (
                                                                                                                                <ProductsSelectionOutlet />
                                                                                                                            ),
                                                                                                                    },
                                                                                                                    {
                                                                                                                        path: ':contractTemplateId',
                                                                                                                        element:
                                                                                                                            (
                                                                                                                                <ProductsConfigurationOutlet
                                                                                                                                    {...baseProps}
                                                                                                                                />
                                                                                                                            ),
                                                                                                                    },
                                                                                                                ],
                                                                                                        },
                                                                                                    ],
                                                                                            },
                                                                                        ],
                                                                                },
                                                                            ],
                                                                    },
                                                                    {
                                                                        path: 'account-statement',
                                                                        element:
                                                                            (
                                                                                <AccountStatementOutlet
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        children:
                                                                            [
                                                                                {
                                                                                    path: ':bkBookingId',
                                                                                    element:
                                                                                        (
                                                                                            <AccountStatementItemDetail />
                                                                                        ),
                                                                                },
                                                                            ],
                                                                    },
                                                                    {
                                                                        path: 'settings',
                                                                        element:
                                                                            (
                                                                                <SettingsRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                    },
                                                                    {
                                                                        path: 'settings/payment',
                                                                        element:
                                                                            (
                                                                                <PaymentSettingsRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        children:
                                                                            [
                                                                                {
                                                                                    path: 'success',
                                                                                    element:
                                                                                        (
                                                                                            <AddPaymentSuccessRoute />
                                                                                        ),
                                                                                },
                                                                                {
                                                                                    path: 'replace',
                                                                                    element:
                                                                                        (
                                                                                            <ReplacePaymentRoute />
                                                                                        ),
                                                                                },
                                                                            ],
                                                                    },
                                                                    {
                                                                        path: 'settings/user',
                                                                        element:
                                                                            (
                                                                                <EditProfileRoute
                                                                                    {...baseProps}
                                                                                />
                                                                            ),
                                                                        children:
                                                                            [
                                                                                {
                                                                                    path: 'email-link-resent',
                                                                                    element:
                                                                                        (
                                                                                            <EmailResentConfirmationRoute />
                                                                                        ),
                                                                                },
                                                                                {
                                                                                    path: 'password',
                                                                                    element:
                                                                                        (
                                                                                            <EditPasswordRoute
                                                                                                {...baseProps}
                                                                                            />
                                                                                        ),
                                                                                },
                                                                                {
                                                                                    path: 'email',
                                                                                    element:
                                                                                        (
                                                                                            <EditEmailRoute
                                                                                                {...baseProps}
                                                                                            />
                                                                                        ),
                                                                                },
                                                                                {
                                                                                    path: 'language',
                                                                                    element:
                                                                                        (
                                                                                            <EditLanguageRoute
                                                                                                {...baseProps}
                                                                                            />
                                                                                        ),
                                                                                },
                                                                                {
                                                                                    path: 'name',
                                                                                    element:
                                                                                        (
                                                                                            <EditNameRoute
                                                                                                {...baseProps}
                                                                                            />
                                                                                        ),
                                                                                },
                                                                            ],
                                                                    },
                                                                ],
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        path: '/not-found',
                                        element: <NotFound />,
                                    },
                                    {
                                        path: '*',
                                        element: <Navigate to="/" replace />,
                                    },
                                ])}
                            />
                        );
                    }}
                />
            )}
        />
    );
}
