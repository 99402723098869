import { Message } from 'dg-web-shared/lib/Localized';
import { VehicleLicensePlateType } from 'dg-web-shared/common/models/Vehicle';

export interface ParkingaboProductListItem {
    contractId: number;
    name: Message;
    remark: string;
    info: Message | null;
    zones: ParkingaboZoneInfo[];
    validFrom: string;
    validTo: string | null;
    priceRappen: number;
    type: ParkingaboProductType;
    state: ParkingaboProductState;
    vehicleType: VehicleLicensePlateType;
    hasBooking: boolean;
}

export interface ParkingaboZoneInfo {
    zoneId: number;
    name: string;
    city: string;
    zipCode: string;
}

export interface ParkingaboCityInfo {
    zipCode: string;
    city: string;
}

export const enum ParkingaboProductType {
    RESERVATION = 'RESERVATION',
    GUEST_RESERVATION = 'GUEST_RESERVATION',
    SUBSCRIPTION = 'SUBSCRIPTION',
    PERMIT_UNTIL_REVOCATION = 'PERMIT_UNTIL_REVOCATION',
    PERMIT_FREE_FIXED_DURATION = 'PERMIT_FREE_FIXED_DURATION',
}

export function productTypeHasPrice(type: ParkingaboProductType): boolean {
    switch (type) {
        case ParkingaboProductType.RESERVATION:
        case ParkingaboProductType.GUEST_RESERVATION:
        case ParkingaboProductType.SUBSCRIPTION:
            return true;
        case ParkingaboProductType.PERMIT_UNTIL_REVOCATION:
        case ParkingaboProductType.PERMIT_FREE_FIXED_DURATION:
            return false;
    }
}

export const enum ParkingaboProductState {
    EXPIRED = 'EXPIRED',
    ACTIVE = 'ACTIVE',
    FUTURE = 'FUTURE',
}
