import { useState } from 'react';
import { DateTime } from 'luxon';
import {
    ParkingaboProductListItem,
    ParkingaboProductType,
} from '../../../shared/ParkingaboProductModels';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ProductDetailBody, ProductOverviewList } from './ProductsDetailOutlet';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BackendRequestErrorMessage } from 'dg-web-shared/common/components/material-ui/BackendRequestErrorMessage';
import { Formatter } from 'dg-web-shared/lib/Date';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';
import {
    MenuListItemColorVariant,
    ParkingaboListItem,
    ParkingaboListItemLabel,
} from '../../../components/ParkingaboMenuListItem.tsx';
import { Delete } from '@mui/icons-material';
import { useTenant } from '../../../components/TenantProvider.tsx';
import {
    productReceiptUrl,
    ReceiptButton,
} from '../account-statement/AccountStatementOutlet.tsx';

interface SubscriptionCancellationPayload {
    contractId: number;
}

interface SubscriptionTerminationPayload {
    contractId: number;
    terminateAt: DateTime;
}

export function ProductDetailSubscription({
    product,
    refetchProducts,
}: {
    product: ParkingaboProductListItem;
    refetchProducts: () => void;
}) {
    const navigate = useNavigate();
    const { tenant } = useTenant();
    const [cancelConfirmDialogOpen, setCancelConfirmDialogOpen] =
        useState(false);
    const [cancelSuccessDialogOpen, setCancelSuccessDialogOpen] =
        useState(false);
    const [cancellationState, cancelSubscription] = useParkingaboServerWrite<
        SubscriptionCancellationPayload,
        ValidationData
    >(({ contractId }) => ({
        url: `/ui-api/parkingabo/user/self/product/${contractId}`,
        method: RequestMethod.DELETE,
    }));
    useServerSuccessEffect(cancellationState, () => {
        setCancelSuccessDialogOpen(true);
    });

    const [terminationConfirmDialogOpen, setTerminationConfirmDialogOpen] =
        useState(false);
    const [terminationSuccessDialogOpen, setTerminationSuccessDialogOpen] =
        useState(false);
    const [terminationState, terminateSubscription] = useParkingaboServerWrite<
        SubscriptionTerminationPayload,
        { terminatedAt: string },
        ValidationData
    >(({ contractId }) => ({
        url: `/ui-api/parkingabo/user/self/product/${contractId}/terminate`,
    }));
    useServerSuccessEffect(terminationState, () => {
        refetchProducts();
        setTerminationSuccessDialogOpen(true);
    });

    if (product.type !== ParkingaboProductType.SUBSCRIPTION) {
        throw new Error('product type must be subscription');
    }

    const now = DateTime.now();
    const validFrom = DateTime.fromISO(product.validFrom);

    const isCancellable = validFrom >= now;
    const terminationDate = now
        .plus({ month: 1 })
        .startOf('month')
        .startOf('day')
        .minus({ millisecond: 1 });

    return (
        <ProductDetailBody>
            <BackendRequestErrorMessage requestState={cancellationState} />
            <BackendRequestErrorMessage requestState={terminationState} />
            <ProductOverviewList product={product} />
            {!product.validTo && (
                <ParkingaboListItem
                    onClick={() =>
                        isCancellable
                            ? setCancelConfirmDialogOpen(true)
                            : setTerminationConfirmDialogOpen(true)
                    }
                    colorVariant={MenuListItemColorVariant.PRIMARY}
                >
                    <ParkingaboListItemLabel
                        loading={
                            cancellationState.status ===
                                RequestStatus.PENDING ||
                            terminationState.status === RequestStatus.PENDING
                        }
                        label={
                            isCancellable ? (
                                <Localized
                                    de="Abo löschen"
                                    fr="Supprimer l'abonnement"
                                    it="Cancella abbonamento"
                                    en="Delete subscription"
                                />
                            ) : (
                                <Localized
                                    de="Abo kündigen"
                                    fr="Résilier l'abonnement"
                                    it="Disdici abbonamento"
                                    en="Cancel subscription"
                                />
                            )
                        }
                        icon={
                            <Delete
                                style={{ fontSize: 36 }}
                                color={'primary'}
                            />
                        }
                    />
                </ParkingaboListItem>
            )}
            <ReceiptButton
                url={productReceiptUrl(tenant.tenantId, product.contractId)}
            />
            <CancellationConfirmDialog
                open={cancelConfirmDialogOpen}
                onAbort={() => setCancelConfirmDialogOpen(false)}
                onConfirm={() => {
                    cancelSubscription({
                        contractId: product.contractId,
                    });
                    setCancelConfirmDialogOpen(false);
                }}
            />
            <CancelSuccessDialog
                open={cancelSuccessDialogOpen}
                onClick={() => {
                    refetchProducts();
                    setCancelSuccessDialogOpen(false);
                    navigate('..');
                }}
            />
            <TerminationConfirmDialog
                open={terminationConfirmDialogOpen}
                terminationDate={terminationDate}
                onAbort={() => setTerminationConfirmDialogOpen(false)}
                onConfirm={() => {
                    terminateSubscription({
                        contractId: product.contractId,
                        terminateAt: terminationDate,
                    });
                    setTerminationConfirmDialogOpen(false);
                }}
            />
            {terminationState.status === RequestStatus.SUCCESS && (
                <TerminationSuccessDialog
                    open={terminationSuccessDialogOpen}
                    terminationDate={DateTime.fromISO(
                        terminationState.data.terminatedAt,
                    )}
                    onClick={() => setTerminationSuccessDialogOpen(false)}
                />
            )}
        </ProductDetailBody>
    );
}

function CancellationConfirmDialog({
    open,
    onAbort,
    onConfirm,
}: {
    open: boolean;
    onAbort: () => void;
    onConfirm: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="warning"
            title={
                <Localized
                    de="Abo löschen"
                    fr="Supprimer l'abonnement"
                    it="Cancella abbonamento"
                    en="Delete subscription"
                />
            }
            onAbort={onAbort}
            onConfirm={onConfirm}
            confirmLabel={
                <Localized
                    de="Löschen"
                    fr="Supprimer"
                    it="Elimina"
                    en="Delete"
                />
            }
        >
            <Typography component="p" sx={{ paddingBottom: 1 }}>
                <Localized
                    de="Wenn Sie das Abo jetzt löschen, "
                    fr="Si vous supprimez l'abonnement maintenant, "
                    it="Cancellando adesso l'abbonamento "
                    en="If you delete the subscription now,  "
                />
                <b>
                    <Localized
                        de="werden keine Kosten"
                        fr="aucun coût ne sera débité"
                        it="non verrà addebitato alcuno costo"
                        en="no costs will be charged"
                    />
                </b>
                <Localized
                    de=" auf Ihrem Parkingabo Konto "
                    fr=" sur votre compte Parkingabo."
                    it=" sul suo conto Parkingabo."
                    en=" to your Parkingabo account."
                />
                <b>
                    <Localized de="belastet." fr="" it="" en="" />
                </b>
            </Typography>
            <Typography>
                <Localized
                    de="Möchten Sie dieses Abo wirklich löschen?"
                    fr="Voulez-vous vraiment supprimer cet abonnement?"
                    it="Vuole veramente cancellare questo abbonamento?"
                    en="Do you really want to delete this subscription?"
                />
            </Typography>
        </FeedbackPopup>
    );
}

function CancelSuccessDialog({
    open,
    onClick,
}: {
    open: boolean;
    onClick: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="success"
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
            abortLabel={'OK'}
            onAbort={onClick}
        >
            <Localized
                de="Das Abo wurde erfolgreich gelöscht."
                fr="L'abonnement a été supprimé avec succès."
                it="L'abbonamento è stata cancellato con successo."
                en="The subscription was successfully deleted."
            />
        </FeedbackPopup>
    );
}

function TerminationConfirmDialog({
    open,
    terminationDate,
    onAbort,
    onConfirm,
}: {
    open: boolean;
    terminationDate: DateTime;
    onAbort: () => void;
    onConfirm: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="warning"
            title={
                <Localized
                    de="Abo kündigen"
                    fr="Résilier l'abonnement"
                    it="Disdici abbonamento"
                    en="Cancel subscription"
                />
            }
            onAbort={onAbort}
            onConfirm={onConfirm}
            confirmLabel={
                <Localized
                    de="Kündigen"
                    fr="Résilier"
                    it="Disdici"
                    en="Terminate"
                />
            }
        >
            <Typography component="p" sx={{ paddingBottom: 1 }}>
                <Localized
                    de="Wenn Sie das Abonnement jetzt kündigen, ist es noch "
                    fr="Si vous résiliez l'abonnement maintenant, il sera encore "
                    it="Disdicendo adesso la abbonamento, quest'ultimo sarà comunque "
                    en="If you cancel the subscription now, it will still be "
                />
                <b>
                    <Localized
                        de="bis am "
                        fr="valable jusqu'à le "
                        it="valido fino al "
                        en="valid until "
                    />
                    <Typography noWrap component="span" fontWeight="bold">
                        {Formatter.dayMonthYear(terminationDate)}
                    </Typography>
                    <Localized de=" gültig." fr="." it="." en="." />
                </b>
            </Typography>
            <Typography>
                <Localized
                    de="Möchten Sie dieses Abo wirklich kündigen?"
                    fr="Voulez-vous vraiment résilier cet abonnement?"
                    it="Vuole veramente disdire questo abbonamento?"
                    en="Do you really want to cancel this subscription?"
                />
            </Typography>
        </FeedbackPopup>
    );
}

function TerminationSuccessDialog({
    open,
    terminationDate,
    onClick,
}: {
    open: boolean;
    terminationDate: DateTime;
    onClick: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="success"
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
            abortLabel={'OK'}
            onAbort={onClick}
        >
            <Localized
                de="Das Abo wurde erfolgreich gekündigt und endet am "
                fr="L'abonnement a été résilié avec succès et sera terminé le "
                it="L'abbonamento è stato disdetto con successo e terminerà il "
                en="The subscription has been successfully cancelled and will end at "
            />
            <Typography noWrap component="span">
                {Formatter.dayMonthYear(terminationDate)}
            </Typography>
            {'.'}
        </FeedbackPopup>
    );
}
