import { useParkingaboServerWrite } from './ParkingaboApi';
import {
    ServerWriteState,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';
import { setAppToken } from '../components/Native.tsx';

export interface SessionValidity {
    appToken: string | null;
}

interface LoginPayload {
    email: string;
    password: string;
}

export function useLoginRequest(): ServerWriteState<
    SessionValidity,
    ValidationData,
    LoginPayload
> {
    const [loginState, sendLogin, reset] = useParkingaboServerWrite<
        LoginPayload,
        SessionValidity
    >(
        () => ({
            url: '/ui-api/parkingabo/session/login',
        }),
        true,
    );

    useServerSuccessEffect(loginState, data => {
        if (data.appToken) {
            setAppToken(data.appToken);
        }

        // we're out of the router
        history.replaceState({}, document.title, '/');
    });

    return [loginState, sendLogin, reset];
}
