import {
    Box,
    Card,
    CardActionArea,
    Chip,
    Link,
    Stack,
    SvgIconTypeMap,
    Typography,
} from '@mui/material';

import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../RouteUtils';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    AccountBalance,
    ChevronRight,
    DriveEta,
    LocalParking,
    Settings,
} from '@mui/icons-material';
import { ParkingaboLink } from '../../components/ParkinaboLink';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { VehicleQrCodeDrawer } from '../../components/VehicleQrCodeDrawer';
import { ParkingaboButton } from '../../components/layout/ParkingaboButton';
import { ParkingaboProductState } from '../../shared/ParkingaboProductModels';
import { ParkingaboLogo } from '../../components/layout/ParkingaboLogoHeader';
import { Outlet, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import {
    AliasInvalidReason,
    CustomerTenantState,
    ParkingaboUser,
    Tenant,
} from '../../shared/ParkingaboModels';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter';
import { FeedbackPopup } from '../../components/FeedbackPopup';
import { useState } from 'react';
import { useTenant } from '../../components/TenantProvider.tsx';
import { TenantAllowedBarrierGateVehicleIdentification } from 'dg-web-shared/model/TenantEnums.ts';
import { ParkingaboVehicle } from 'dg-web-shared/common/models/Vehicle.tsx';
import { isLicensePlateExpected } from 'product-shared/tenant/TenantVehicleIdentificationUtils.ts';
import { envIsProduction, envName } from 'dg-web-shared/lib/Environment.ts';

export function HomeRoute({ products, user, vehicles }: AuthedRouteCompProps) {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const { tenant } = useTenant();
    const navigate = useNavigate();
    const [openOverduePaymentDialog, setOpenOverduePaymentDialog] =
        useState(false);

    return (
        <Box
            sx={{
                height: '100%',
                position: 'relative',
                backgroundColor: theme => theme.palette.primary.light,
            }}
        >
            <Box
                sx={theme => ({
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: envIsProduction()
                        ? theme.palette.primary.main
                        : theme.palette.warning.light,
                    color: theme.palette.primary.contrastText,
                    padding: '29px 32px 29px 26px',
                })}
            >
                <ParkingaboLogo width={237} height={50} />
                <ParkingaboLink to={generateAuthedParkingaboPath('settings')}>
                    <ParkingaboButton
                        sx={{
                            width: 40,
                            height: 40,
                            minWidth: 40,
                            backgroundColor: theme =>
                                theme.palette.primary.contrastText,
                            ['&:hover']: {
                                backgroundColor: theme =>
                                    theme.palette.primary.light,
                            },
                        }}
                    >
                        <Settings />
                    </ParkingaboButton>
                </ParkingaboLink>
            </Box>
            <AccountStateAlerts
                tenant={tenant}
                user={user}
                vehicles={vehicles.data}
                setOpenOverduePaymentDialog={() =>
                    setOpenOverduePaymentDialog(true)
                }
            />
            <Box sx={{ padding: theme => theme.spacing(4) }}>
                <Typography
                    variant="h1"
                    color="inherit"
                    sx={theme => ({
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(3),
                        textAlign: 'center',
                        marginBottom: theme.spacing(1),
                    })}
                >
                    {tenant.tenantName}
                </Typography>
                <Stack spacing={2}>
                    <ButtonLink
                        to={'products'}
                        label={
                            <Localized
                                de="Produkte"
                                fr="Produits"
                                it="Prodotti"
                                en="Products"
                            />
                        }
                        Icon={LocalParking}
                        counter={
                            products.filter(
                                product =>
                                    product.state ===
                                    ParkingaboProductState.ACTIVE,
                            ).length
                        }
                    />
                    <ButtonLink
                        to={'account-statement'}
                        label={
                            <Localized
                                de="Kontoauszug"
                                fr="Relevé de compte"
                                it="Estratto conto"
                                en="Account statement"
                            />
                        }
                        Icon={AccountBalance}
                        counter={0}
                    />
                    <ButtonLink
                        to={'vehicles'}
                        label={
                            <Localized
                                de="Fahrzeuge"
                                fr="Véhicules"
                                it="Veicoli"
                                en="Vehicles"
                            />
                        }
                        Icon={DriveEta}
                        counter={vehicles.data.length}
                    />
                </Stack>
                {!envIsProduction() && (
                    <Typography
                        sx={{
                            textAlign: 'center',
                            marginTop: 3,
                        }}
                        fontWeight="bold"
                    >
                        {envName()}
                    </Typography>
                )}
                <FeedbackPopup
                    open={openOverduePaymentDialog}
                    title={
                        <Localized
                            de="Zahlungsverzug"
                            fr="Paiements en souffrance"
                            it="Pagamenti in arretrato"
                            en="Overdue payments"
                        />
                    }
                    onConfirm={() =>
                        navigate(
                            generateAuthedParkingaboPath('payment/register'),
                        )
                    }
                    onAbort={() => setOpenOverduePaymentDialog(false)}
                    confirmLabel={
                        <Localized
                            de="Bezahlen"
                            fr="Payer"
                            it="Paga"
                            en="Pay"
                        />
                    }
                    abortLabel={
                        <Localized
                            de="Abbrechen"
                            fr="Annuler"
                            it="Annulla"
                            en="Cancel"
                        />
                    }
                >
                    <>
                        <p>
                            <Localized
                                de="Eine oder mehrere automatische Zahlungen am Ende des Monats konnten nicht durchgeführt werden."
                                fr="Un ou plusieurs paiements automatiques à la fin du mois n'ont pas pu être effectués."
                                it="Uno o più pagamenti automatici a fine mese non hanno potuto essere eseguiti."
                                en="One or more automatic payments at the end of the month could not be made."
                            />
                        </p>
                        <p>
                            <Localized
                                de="Wenn Sie auf «Bezahlen» klicken, können Sie den gesamten negativen Saldo Ihres Kontos ausgleichen (einschließlich allfälliger zwischenzeitlicher neuer Belastungen)."
                                fr="En cliquant sur «Payer», vous pouvez régler la totalité du solde négatif de votre compte (y compris les nouveaux débits intervenus entre-temps)."
                                it="Cliccando su «Paga» potrà saldare l’intero saldo negativo del suo conto (incl. nuovi addebiti sopraggiunti nel frattempo)."
                                en="By clicking on «Pay» you can settle the entire negative balance of your account (including any new debits in the meantime)."
                            />
                        </p>
                    </>
                </FeedbackPopup>
                <Outlet />
            </Box>
            {tenant.allowedBarrierGateVehicleIdentification ===
                TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_QR && (
                <VehicleQrCodeDrawer vehicles={vehicles.data} />
            )}
        </Box>
    );
}

function ButtonLink({
    to,
    label,
    Icon,
    counter,
}: {
    to: string;
    label: React.ReactNode;
    Icon: OverridableComponent<SvgIconTypeMap>;
    counter: number;
}) {
    return (
        <ParkingaboLink to={to} underline="none">
            <Card elevation={0}>
                <CardActionArea
                    sx={theme => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: theme.palette.background.default,
                        paddingX: theme.spacing(2),
                        paddingY: theme.spacing(3),
                    })}
                >
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                            sx={theme => {
                                const h3FontSize = theme.typography.h3.fontSize;
                                return {
                                    fontSize:
                                        typeof h3FontSize === 'number'
                                            ? h3FontSize * 2
                                            : theme.typography.h2.fontSize,
                                };
                            }}
                        />
                        <Typography
                            variant="h3"
                            component="h2"
                            sx={{
                                marginLeft: theme => theme.spacing(1),
                                textTransform: 'none',
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {counter > 0 && (
                            <Chip
                                label={counter}
                                color="primary"
                                sx={{
                                    marginRight: theme => theme.spacing(1),
                                    minWidth: 24,
                                    height: 24,
                                }}
                            />
                        )}
                        <ChevronRight />
                    </Box>
                </CardActionArea>
            </Card>
        </ParkingaboLink>
    );
}

function AccountStateAlerts({
    tenant,
    user,
    vehicles,
    setOpenOverduePaymentDialog,
}: {
    tenant: Tenant;
    user: ParkingaboUser;
    vehicles: ParkingaboVehicle[];
    setOpenOverduePaymentDialog: () => void;
}) {
    const vehiclesWithoutLp = vehicles.filter(v => !v.licensePlateNr).length;
    return (
        <>
            {user.customerState === CustomerTenantState.LOCKED && (
                <Alert
                    severity="error"
                    sx={{ backgroundColor: 'rgb(255, 195, 195)' }}
                >
                    <Typography>
                        <Localized
                            de="Konto gesperrt"
                            fr="Compte bloqué"
                            it="Conto bloccato"
                            en="Account locked"
                        />
                    </Typography>
                </Alert>
            )}
            {user.overduePaymentsAmountRappen !== null ? (
                <Alert
                    severity="warning"
                    sx={{ backgroundColor: 'rgb(255, 235, 195)' }}
                >
                    <Typography>
                        <Localized
                            de={`Zahlungsverzug: ${currencyCentsToLocalPrice(
                                'de',
                                user.overduePaymentsAmountRappen,
                            )} `}
                            fr={`Montant en retard: ${currencyCentsToLocalPrice(
                                'fr',
                                user.overduePaymentsAmountRappen,
                            )} `}
                            it={`Importo in arretrato: ${currencyCentsToLocalPrice(
                                'it',
                                user.overduePaymentsAmountRappen,
                            )} `}
                            en={`Overdue amount: ${currencyCentsToLocalPrice(
                                'en',
                                user.overduePaymentsAmountRappen,
                            )} `}
                        />
                        <Link
                            onClick={() => setOpenOverduePaymentDialog()}
                            underline="always"
                            sx={{ cursor: 'pointer' }}
                        >
                            <Localized
                                de="(mehr)"
                                fr="(plus)"
                                it="(di più)"
                                en="(more)"
                            />
                        </Link>
                    </Typography>
                </Alert>
            ) : (
                user.aliasInvalidReason && (
                    <Alert
                        severity="warning"
                        sx={{ backgroundColor: 'rgb(255, 235, 195)' }}
                    >
                        <Typography>
                            <AliasInvalidAlertMessage
                                aliasInvalidReason={user.aliasInvalidReason}
                            />
                        </Typography>
                    </Alert>
                )
            )}
            {isLicensePlateExpected(
                tenant.allowedEnforcedVehicleIdentification,
                tenant.allowedBarrierGateVehicleIdentification,
            ) &&
                vehiclesWithoutLp !== 0 && (
                    <Alert
                        severity="warning"
                        sx={{ backgroundColor: 'rgb(255, 235, 195)' }}
                    >
                        <Typography>
                            {vehiclesWithoutLp === 1 ? (
                                <Localized
                                    de="1 Fahrzeug ohne Kennzeichen"
                                    fr="1 véhicule sans plaque"
                                    it="1 veicolo senza targa"
                                    en="1 vehicle without licence plate"
                                />
                            ) : (
                                <Localized
                                    de={`${vehiclesWithoutLp} Fahrzeuge ohne Kennzeichen`}
                                    fr={`${vehiclesWithoutLp} véhicules sans plaque`}
                                    it={`${vehiclesWithoutLp} veicoli senza targa`}
                                    en={`${vehiclesWithoutLp} vehicles without licence plate`}
                                />
                            )}
                        </Typography>
                    </Alert>
                )}
        </>
    );
}

function AliasInvalidAlertMessage({
    aliasInvalidReason,
}: {
    aliasInvalidReason: AliasInvalidReason;
}) {
    switch (aliasInvalidReason) {
        case AliasInvalidReason.EXPIRED:
            return (
                <Localized
                    de="Zahlungsmittel abgelaufen"
                    fr="Moyen de paiement échu"
                    it="Mezzo di pagamento scaduto"
                    en="Payment mean expired"
                />
            );
        case AliasInvalidReason.LAST_DIRECT_FAILED:
            return (
                <Localized
                    de="Zahlungsmittel abgelehnt"
                    fr="Moyen de paiement rejeté"
                    it="Mezzo di pagamento rifiutato"
                    en="Payment mean rejected"
                />
            );
        case AliasInvalidReason.ONBOARDING_REQUIRED:
        case AliasInvalidReason.NOT_FOUND:
            return (
                <Localized
                    de="Zahlungsmittel fehlt"
                    fr="Moyen de paiement manquant"
                    it="Mezzo di pagamento mancante"
                    en="Payment mean missing"
                />
            );
    }
}
